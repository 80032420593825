import { gql } from "graphql-tag";

export const SUBSCRIPTION_STATUS_FIELDS = gql`
  fragment SubscriptionStatusFields on SystemSettingsStatus {
    id,
    key,
    status
  }
`;

export default { SUBSCRIPTION_STATUS_FIELDS };
