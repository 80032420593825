
import { defineComponent, ref, onMounted } from "vue";
import { useI18n } from "vue-i18n";
import { Apollo, Notify } from "@/core/services";
import { GET_SUBSCRIPTION_STATUS } from "../graphql/Queries";
import { UPDATE_SYSTEM_STATUS } from "@/modules/system/system-settings-status/graphql/Mutations";
import InnerLoader from "../../../../components/InnerLoader.vue";

export default defineComponent({
	name: "payment-methods-list",
	components: { InnerLoader },
	setup() {
		const loader = ref(false);
		const loading = ref(false);
		const statuses = ref([]);
		const systemSettings = ref();
		const i18n = useI18n();

		const capitalizeWords = (string) => {
			return string.replace(/(?:^|\s)\S/g, function(a) {
				return a.toUpperCase();
			});
		};

		const updateSubscriptionStatus = (id: number) => {
			loading.value = true;
			Apollo.mutate({
				mutation: UPDATE_SYSTEM_STATUS,
				variables: { id: id },
				update: (store, { data: { data } }) => {
					systemSettings.value = data?.system_settings_status;
					loading.value = false;
					Notify.success(i18n.t("message.RECORD_UPDATED_SUCCESSFULLY"));
				},
			}).catch(() => {
				loading.value = false;
			});
		};

		const getSubscriptionStatus = () => {
			loading.value = true;
			Apollo.watchQuery({
				query: GET_SUBSCRIPTION_STATUS,
				fetchPolicy: "network-only",
				nextFetchPolicy: "cache-only",
				errorPolicy: "all",
			}).subscribe(({ data, errors }) => {
				if (errors) {
					loading.value = false;
				}
				systemSettings.value = data?.system_settings_status;
				loading.value = false;
			});
		};

		onMounted(() => {
			getSubscriptionStatus();
		});

		return {
			statuses,
			loader,
			loading,
			systemSettings,
			capitalizeWords,
			updateSubscriptionStatus,
		};
	},
});
