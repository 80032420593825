import { gql } from "graphql-tag";
import { SUBSCRIPTION_STATUS_FIELDS } from "./Fragments";

// export const CREATE_HOME_SCREEN = gql`
//   mutation CreateHomeScreen($input: HomeScreenInput!) {
//     create_home_screen(home_screen: $input) {
//       ...HomeScreenFields
//     }
//   }
//   ${HOME_SCREEN_FIELDS}
// `;

export const UPDATE_SYSTEM_STATUS = gql`
  mutation UpdateSystemStatus($id: Int!) {
    update_system_status(id:$id) {
      ...SubscriptionStatusFields
    }
  }
  ${SUBSCRIPTION_STATUS_FIELDS}
`;

export default { UPDATE_SYSTEM_STATUS };
