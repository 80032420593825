import {gql} from "graphql-tag";
import {SUBSCRIPTION_STATUS_FIELDS} from "./Fragments";

export const GET_SUBSCRIPTION_STATUS = gql`
    query GetSystemSettings {
            system_settings_status {
                    ...SubscriptionStatusFields
            }
    }
    ${SUBSCRIPTION_STATUS_FIELDS}
`;


export default {GET_SUBSCRIPTION_STATUS};
