
import { setPageHeader } from "@/core/helpers/toolbar";
import { defineComponent, onMounted } from "vue";
import SystemSettingsStatus from "../components/SystemSettingsStatus.vue";

export default defineComponent({
	name: "subscription status",
	components: {
		SystemSettingsStatus,
	},
	setup() {
		onMounted(() => {
			setPageHeader({
				title: "message.SUBSCRIPTION_STATUS message.LIST",
				actionButton: {
					// ability: "add_home_screen",
					pageAction: {
						action: "addHomeScreenAction",
					},
				},
				breadCrumbs: [
					{ name: "message.HOME", link: "/dashboard" },
					{ name: "message.SYSTEM", link: "#" },
					{ name: "message.SETTINGS", link: "/system/settings" },
					{ name: "message.SUBSCRIPTION_STATUS message.LIST" },
				],
			});
		});
	},
});
